@font-face {
  font-family: "TeX Gyre Heros";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/texgyreheros/texgyreheros-regular-webfont.eot?") format("eot"), url("../fonts/texgyreheros/texgyreheros-regular-webfont.woff") format("woff"), url("../fonts/texgyreheros/texgyreheros-regular-webfont.ttf") format("truetype"), url("../fonts/texgyreheros/texgyreheros-regular-webfont.svg#TeX_Gyre_Heros") format("svg");
}
@font-face {
  font-family: "TeX Gyre Heros";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/texgyreheros/texgyreheros-bold-webfont.eot?") format("eot"), url("../fonts/texgyreheros/texgyreheros-bold-webfont.woff") format("woff"), url("../fonts/texgyreheros/texgyreheros-bold-webfont.ttf") format("truetype"), url("../fonts/texgyreheros/texgyreheros-bold-webfont.svg#TeX_Gyre_Heros") format("svg");
}
@font-face {
  font-family: "TeX Gyre Heros";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/texgyreheros/texgyreheros-bolditalic-webfont.eot?") format("eot"), url("../fonts/texgyreheros/texgyreheros-bolditalic-webfont.woff") format("woff"), url("../fonts/texgyreheros/texgyreheros-bolditalic-webfont.ttf") format("truetype"), url("../fonts/texgyreheros/texgyreheros-bolditalic-webfont.svg#TeX_Gyre_Heros") format("svg");
}
@font-face {
  font-family: "TeX Gyre Heros";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/texgyreheros/texgyreheros-italic-webfont.eot?") format("eot"), url("../fonts/texgyreheros/texgyreheros-italic-webfont.woff") format("woff"), url("../fonts/texgyreheros/texgyreheros-italic-webfont.ttf") format("truetype"), url("../fonts/texgyreheros/texgyreheros-italic-webfont.svg#TeX_Gyre_Heros") format("svg");
}
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/roboto/Roboto-Light.eot?") format("eot"), url("../fonts/roboto/Roboto-Light.woff") format("woff"), url("../fonts/roboto/Roboto-Light.ttf") format("truetype"), url("../fonts/roboto/Roboto-Light.svg#Roboto_Light") format("svg");
}
@font-face {
  font-family: "Roboto Light";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/roboto/Roboto-LightItalic.eot?") format("eot"), url("../fonts/roboto/Roboto-LightItalic.woff") format("woff"), url("../fonts/roboto/Roboto-LightItalic.ttf") format("truetype"), url("../fonts/roboto/Roboto-LightItalic.svg#Roboto_Light") format("svg");
}
.formresult {
  font-size: 1em;
  margin: 25px 0;
  padding: 15px;
  /*width: 350px;*/
  background-color: #fff;
  color: #000;
}

.displayOK {
  border: 1px solid #999;
}

.displayErr {
  border: 2px solid #f00;
}

.requiredLabel {
  color: #c00;
  font-weight: bold;
}

.form input,
.form textarea,
.form select {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
  margin: 0 0 2px 0;
  padding: 0.25em 0 0.25em 2px;
  max-width: 21em;
  font-size: inherit;
  line-height: normal;
}
@media only screen and (max-width: 37.4375em) {
  .form input,
.form textarea,
.form select {
    max-width: 100% !important;
    width: 100%;
  }
}
.form textarea {
  width: 100%;
}
.form select {
  padding: 0;
}
.form input[type=submit],
.form input[type=button] {
  padding: 4px 6px;
  font-size: 1.2em;
  font-weight: bold;
  background-color: #eee;
}
@media only screen and (max-width: 37.4375em) {
  .form input[type=submit],
.form input[type=button] {
    width: auto;
  }
}
.form input[type=radio],
.form input[type=checkbox],
.form input[type=image] {
  display: inline;
  border: none;
  background: none;
}
@media only screen and (max-width: 37.4375em) {
  .form input[type=radio],
.form input[type=checkbox],
.form input[type=image] {
    width: auto;
  }
}
.form .requiredField {
  border-left: 3px solid #c00;
}
.form input[required],
.form select[required],
.form textarea[required] {
  border-top: 2px solid #ccc;
  border-left: 3px solid #c00;
}
.form label {
  font-weight: bold;
}
.form fieldset {
  margin: 1em 0;
  border: none;
  width: auto;
  padding: 0;
}
.form fieldset legend {
  font-weight: bold;
  font-size: 1em;
  margin: 0;
  line-height: 1em;
}
.form input::placeholder,
.form textarea::placeholder {
  color: #888;
  font-style: italic;
}
.form input::placeholder:focus,
.form textarea::placeholder:focus {
  color: inherit;
  font-style: inherit;
}
.form input:-ms-input-placeholder,
.form textarea:-ms-input-placeholder {
  color: #888;
  font-style: italic;
}
.form input:-ms-input-placeholder:focus,
.form textarea:-ms-input-placeholder:focus {
  color: inherit;
  font-style: inherit;
}
.form .form-field {
  margin: 1em 0;
}
.form .field-error {
  color: #c00;
}
.form .combobox-help-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.form .combobox-container {
  position: relative;
  max-width: 250px;
}
.form .combobox-suggestions {
  position: absolute;
  left: 0;
  width: 250px;
  background: #fff;
  z-index: 2;
}
.form .combobox-suggestion {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  text-align: left;
  padding: 0.5em;
}
.form .combobox-suggestion:first-child {
  border-top: 1px solid #ccc;
}
.form .combobox-clear-button {
  display: none;
}
.form .js-combobox[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.form .js-combobox[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}