// Import settings, functions & variables
@import "mixins";
@import "variables"; // global vars
@import "fonts"; // activate font face definitions for local fonts (if needed), otherwise load from Google Fonts

.formresult {
	font-size: 1em;
	margin: 25px 0;
	padding: 15px;
	/*width: 350px;*/
	background-color: #fff;
	color: #000;
}
.displayOK { border: 1px solid #999; }
.displayErr { border: 2px solid #f00; }
.requiredLabel { color: #c00; font-weight: bold; }

.form {
	input,
	textarea,
	select {
		border: 1px solid #ccc;
		background-color: #fff;
		color: #000;
		margin: 0 0 2px 0;
		padding: 0.25em 0 0.25em 2px;
		max-width: 21em;
		font-size: inherit;
		line-height: normal;
		@media only screen and (max-width: $breakpoint-xs-max) {
			max-width: 100% !important;
			width: 100%;
		}
	}
	textarea {
		width: 100%;
	}
	select {
		padding: 0;
	}
	input[type=submit],
	input[type=button] {
		padding: 4px 6px;
		font-size: 1.2em;
		font-weight: bold;
		background-color: #eee;
		@media only screen and (max-width: $breakpoint-xs-max) {
			width: auto;
		}
	}
	input[type=radio],
	input[type=checkbox],
	input[type=image] {
		display: inline;
		border: none;
		background: none;
		@media only screen and (max-width: $breakpoint-xs-max) {
			width: auto;
		}
	}
	.requiredField { border-left: 3px solid #c00; }
	input[required],
	select[required],
	textarea[required] {
		border-top: 2px solid #ccc;
		border-left: 3px solid #c00;
	}
	label {
		font-weight: bold;
	}
	fieldset {
		margin: 1em 0;
		border: none;
		width: auto;
		padding: 0;
		legend {
			font-weight: bold;
			font-size: 1em;
			margin: 0;
			line-height: 1em;
		}
	}

	input,
	textarea {
		&::placeholder {
			color: #888;
			font-style: italic;
			&:focus {
				color: inherit;
				font-style: inherit;
			}
		}
		&:-ms-input-placeholder {
			color: #888;
			font-style: italic;
			&:focus {
				color: inherit;
				font-style: inherit;
			}
		}
	}

	.form-field {
		margin: 1em 0;
	}
	.field-error {
		color: #c00;
	}

	// START Jquery Accessible Autocomplete List CSS (https://a11y.nicolas-hoffmann.net/autocomplete-list/)
	.combobox-help-text {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.combobox-container {
		position: relative;
		max-width: 250px;
		//margin: 0 auto;
	}
	.combobox-suggestions {
		position: absolute;
		left: 0;
		width: 250px;
		background: #fff;
		z-index: 2;
	}
	.combobox-suggestion {
		cursor: pointer;
		//color: #666;
		border-bottom: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		text-align: left;
		padding: 0.5em;
		&:first-child {
			border-top: 1px solid #ccc;
		}
		&:hover,
		&:focus {
			//color: #555;
		}
	}
	.combobox-clear-button {
		display: none;
		//position: absolute;
		//right: 1px;
		//font-family: inherit;
		//font-size: 1em;
		//padding: .5em;
		//background: transparent
	}
	// http://geektnt.com/how-to-remove-x-from-search-input-field-on-chrome-and-ie.html
	.js-combobox[type=text]::-ms-clear { display: none; width: 0; height: 0; }
	.js-combobox[type=text]::-ms-reveal { display: none; width: 0; height: 0; }
	// END Jquery Accessible Autocomplete List CSS
}
